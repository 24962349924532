.hamburger {
    width: 130px;
    height: 60px;
    position: absolute;
    z-index: 99999999;
    right: 20px;
    top: 20px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    display: block;
    @media only screen and (min-width: $medium) {
        display: none;
    }
    &:before {
        content: 'Menu';
        position: absolute;
        top: 14px;
        left: 20px;
        color: #fff;
        font-weight: 400;
        @media only screen and (min-width: $medium) {
            top: 12px;
        }
    }
    &__holder {
        position: absolute;
        top: 20px;
        width: 20px;
        height: 20px;
        right: 20px;
    }
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: #fff;
        opacity: 1;
        right: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 8px;
        }
        &:nth-child(4) {
            top: 16px;
        }
    }
    &.open {
        &:before {
            content: 'Sluit';
        }
        span:nth-child(1) {
            opacity: 0;
        }
        span:nth-child(2) {
            transform: rotate(45deg);
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
        span:nth-child(4) {
            opacity: 0;
        }
    }
}
