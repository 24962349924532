.z-0 {
	z-index: 0;
}
.z-1 {
	z-index: 1;
}
.z-2 {
	z-index: 2;
}
.z-3 {
	z-index: 3;
}
.z-ridiculous {
	z-index: 10000;
}
