@each $name, $colour in $theme-colours {
	.svg-fill\:#{$name} {
		fill: $colour;
	}
}
@each $name, $colour in $theme-colours {
	.svg-stroke\:#{$name} {
		stroke: $colour;
	}
}
