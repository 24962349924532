.cookie {
    &__melding {
        .button {
            bottom: 24px;
            width: calc(100% - 32px);
        }
    }
    &__bottom {
        padding-top: 24px;
        padding-bottom: 104px;
    }
}
@media screen and (min-width: $medium) {
    .cookie {
        padding: 40px 80px;
        &__melding {
            .button {
                bottom: 40px;
                width: calc(100% - 112px);
            }
        }
        &__bottom {
            padding-top: 40px;
            padding-bottom: 96px;
        }
    }
}
