.brands {
    &__item {
        text-align: center;
        img {
            max-height: 64px;
            text-align: center;
            width: auto;
        }
        &.greyscaled {
            opacity: .3;
            &:hover {
                opacity: 1;
            }
        }
    }
    .slick-slide {
        display: flex !important;
        align-items: center;
        height: 120px;
    }
}
