form {
    * {
        color: $grey;
        outline: none;
        border-radius: 0;
        &::placeholder {
            color: $grey !important;
            opacity: 1 !important;
        }
    }
    label {
        font-size: 14px;
        font-weight: 500;
        textarea,
        input {
            margin-top: .25rem;
            margin-bottom: 1rem;
        }
    }
    textarea,
    input {
        font-family: $base-font-family;
        border: 1px solid $grey-light;
        resize: none;
        padding: 11px 16px;
        &:focus {
            border: 1px solid $primary;
        }
    }
    textarea {
        height: 96px;
    }
    button {
        background-color: $primary;
        color: $white;
        padding: 12px 72px 12px 24px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
        float: right;
        &:after {
            right: 24px;
            position: absolute;
            content: '';
            background-image: url('/img/icons/pijltje-rechts-wit.svg');
            display: inline-block;
            background-position: center right;
            background-repeat: no-repeat;
            border-left: 1px solid $grey-light;
            width: 8px;
            height: 26px;
            padding-left: 24px;
        }
    }
}
@media screen and (min-width: $medium) {
    form {
        button {
            padding: 19px 72px 19px 24px;
            font-size: 1rem;
        }
        label {
            input {
                margin-top: .5rem;
                margin-bottom: 40px;
            }
        }
    }
}

#debijenvanger { display: none; }