html,body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
    margin:  0;
    padding: 0;
}

ul,
ol {
    padding: 0;
    margin: 0;
	list-style-type: none;
}

select,
input {
	appearance: none;
	border: none;
	border-radius: 0;
}
