.icon-bg {
    padding: 10px 14px;
    border-radius: 60px;
    display: inline-block;
    float: left;
    margin-right: 20px;
}

.stage-timeline .icon {
    box-shadow: 0 2px 8px 0 rgba(27, 63, 111, 0.1);
    border-radius: 70px;
    height: 50px;
    width: 50px;
    padding-top: 6px;
    display: inline-block;
    z-index: 999;
    position: relative;
    &:after {
        content: ' ';
        bottom: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        height: 10px;
        width: 2px;
        z-index: -1;
        background-color: rgba(70, 70, 70, 0.2);
    }
    &.top:after {
        content: ' ';
        top: -10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        height: 10px;
        width: 2px;
        z-index: 0;
        background-color: rgba(70, 70, 70, 0.2);
    }
}

.mobileStages .icon {
    box-shadow: 0 2px 8px 0 rgba(27, 63, 111, 0.1);
    border-radius: 70px;
    height: 40px;
    width: 40px;
    padding-top: 3px;
    display: inline-block;
    z-index: 999;
    position: relative;
    margin-left: 10px;
    text-align: center;
}
