//Everything should be border box because it's great!
html {
    box-sizing: border-box;
}

* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}
