ul {
    li {
        padding-left: 23px;
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            content: '';
            background-color: $blue;
            width: 7px;
            height: 7px;
            margin-top: 9px;
            border-radius: 50%;
            display: block;
        }
    }
}

input[type="radio"] {
    display: none;
}

input[type="radio"] + label span {
    display: inline-block;
    width: 19px;
    height: 19px;
    margin: -2px 10px 0 0;
    vertical-align: middle;
    background: red;
    cursor: pointer;
    border-radius: 50%;
    background-color: $white;
}

input[type="radio"]:checked + label span {
    background: $white;
    border: 5px solid $blue;
}
.main-menu {
    .menu-item {
        li {
            padding: 0;
            &:before {
                display: none;
            }
        }
    }
}
