p,
a,
li,
blockquote,
form,
span,
textarea,
input {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

p {
    margin-top: 0;
    margin-bottom: 16px;
    display: inline-block;
    &.two-columns {
        column-count: 1;
        -webkit-column-gap: 32px; /* Chrome, Safari, Opera */
        -moz-column-gap: 32px; /* Firefox */
        column-gap: 32px;
    }
    &.breadcrumbs {
        font-size: 12px;
        span {
            &:not(:last-child) {
                background-image: url('/img/site/icons/volgende-icon/color3.svg');
                background-repeat: no-repeat;
                background-position: center right;
                padding-right: 13px;
            }
        }
    }
}


::selection {
    background: #0082c8; /* WebKit/Blink Browsers */
    color: #fff;
}
::-moz-selection {
    background: #0082c8; /* Gecko Browsers */
    color: #fff;
}

a {
  text-decoration: none;
  color: $grey;
  font-size: 15px;
}

.matrixElement a {
    color: #ef444e;
    text-decoration: underline;
}

a.white {
    color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    color: $primary;
    text-transform: uppercase;
}

// as determined by design
h1,
.h1 {
    @include font-size(31px);
    line-height: rem-calc(37px);
    font-family: $header-font-family;
}

@media screen and (min-width: $medium) {
    h1,
    .h1 {
        @include font-size(43px);
        line-height: rem-calc(52px);
    }
}

h2,
.h2 {
    @include font-size(22px);
    line-height: rem-calc(26px);
    font-family: $header-font-family;
    margin-bottom: 1rem;
}

@media screen and (min-width: $medium) {
    h2,
    .h2 {
        @include font-size(24px);
        line-height: rem-calc(29px);
        margin-bottom: 1.5rem;
    }
}

h3,
.h3 {
    @include font-size(20px);
    line-height: rem-calc(24px);
    font-family: $header-font-family;
}

@media screen and (min-width: $medium) {
    h3,
    .h3 {
        @include font-size(21px);
        line-height: rem-calc(25px);
        margin-bottom: 1.5rem;
    }
}

h4,
.h4 {
    @include font-size(18px);
    line-height: rem-calc(22px);
    font-family: $header-font-family;
}

@media screen and (min-width: $medium) {
    h4,
    .h4 {
        @include font-size(19px);
        line-height: rem-calc(23px);
    }
}

h5,
.h5 {
    @include font-size(16px);
    line-height: rem-calc(19px);
    font-family: $header-font-family;
}

@media screen and (min-width: $medium) {
    h5,
    .h5 {
        @include font-size(17px);
        line-height: rem-calc(20px);
    }
}

h6,
.h6 {
    @include font-size(14px);
    line-height: rem-calc(17px);
    color: $blue;
    font-family: $header-font-family;
}

@media screen and (min-width: $medium) {
    h6,
    .h6 {
        @include font-size(15px);
        line-height: rem-calc(18px);
    }
}

::selection {
    background-color: #ef444e;
    color: #fff;
    background-color: var(--select-bg);
    color: var(--select-text);
}
footer {
    h3 {
        font-family: 'Kalam';
        text-transform: none;
    }
}

.special-font {
    font-family: 'Kalam';
    text-transform: none;
    font-weight: 700;
    color: #0082c8 !important;
}

@media screen and (min-width: $medium) {
    p,
    span {
        font-size: 16px;
        line-height: 26px;
    }
    .two-columns {
        column-count: 2;
    }
    .header {
        h1 {
            word-spacing: 9999px;
        }
    }
}
