html, body {
    height: 100%;
    width: 100%;
    font-size: $base-font-size;
    line-height: $base-line-height;
    overflow-x: hidden;
}
main {
    border: .5rem solid $white;
    background-color: $grey-opacity;
    &.nav-open {
        height: 100vh;
        overflow: hidden;
    }
}
body {
    font-family: $base-font-family;
    color: $colour-font;
    height: auto;
    &.projecten {
        padding: 0;
    }
}
.homepage {
    .main-content {
        padding: 0;
    }
}
.main-content {
    width: 100%;
    padding-top: 80px;
}
.projecten {
    .main-content {
        padding-top: 24px;
    }
}
.over-ons,
.detail {
    padding: 250px 0;
    background-size: cover;
    background-position: center;
    &__video {
        height: 320px;
        background-size: cover;
        background-position: center;
    }
}
// Contain images and iframe to their parent, rarely do we want them escaping their parent.
img, iframe {
    max-width: 100%;
}

// This gets around width and height attributes on images.
img {
    width: 100%;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
@media screen and (min-width: $medium) {
    .projecten,
    .homepage {
        .main-content {
            padding-top: 80px;
        }
    }
    .main-content {
        width: calc(100% - 80px);
    }
}
