.button,
.text-button,
button {
    cursor: pointer;
}
.button {
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    padding: 22px 40px;
    text-decoration: none;
    border: 0;
    outline: none;
    overflow: hidden;
    font-size: 15px;
    line-height: 20px;
    color: white;
    background-color: $primary-light;
    background-image: url("/img/site/icons/pijltje-rechts-wit.svg");
    background-repeat: no-repeat;
    background-position: center right 16px;
    transition: .5s;
    padding-right: 64px;
    &.project-button-fix {
        position: absolute;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &:disabled {
        opacity: .6;
    }
    &.scroll {
        background-image: url("/img/site/icons/pijltje-beneden-wit.svg");
        &:hover {
            background-position: bottom 16px center;
        }
    }
    &.prev {
        background-image: url("/img/site/icons/pijltje-links-wit.svg");
        &:hover {
            background-position: center right 32px;
        }
    }
    &:hover {
        background-position: center right 16px;
    }
    &--arrow,
    &--linkedin,
    &--instagram,
    &--flag,
    &--plus {
        padding: 32px;
        background-position: center;
        cursor: pointer;
        overflow: hidden;
    }
    &--instagram,
    &--linkedin,
    &--flag {
        &:hover {
            background-position: center;
        }
    }
    &--linkedin {
        margin-bottom: 40px;
        background-image: url("/img/site/icons/linkedIn-icon.svg");
        position: relative;

        &::after {
            content: "";
            height: 32px;
            width: 1px;
            background: rgba(255,255,255,0.2);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: block;
        }

        @media screen and (min-width: $medium) {
            position: absolute;
            margin-bottom: 80px;

            &::after {
                display: none;
            }
        }
    }
    &--instagram {
        margin-bottom: 40px;
        background-image: url("/img/site/icons/instagram-icon.svg");

        @media screen and (min-width: $medium) {
            margin-bottom: 160px;
        }
    }
    &--flag {
        z-index: 9999;
        top: 8px;
        left: 144px;

        @media screen and (min-width: $medium) {
            left: 72px;
        }

        &.nl {
            background-image: url("/img/site/icons/vlag-taal-nederlands.png");
        }
        &.en {
            background-image: url("/img/site/icons/vlag-taal-engels.png");
        }
    }
    &--plus {
        background-image: url("/img/site/icons/plus-icon.svg");
    }
    &--play-button {
        position: absolute;
        height: 80px;
        width: 80px;
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        transform: translateY(-50%);
        background-color: $blue;
        opacity: .6;
        background-image: url("/img/site/icons/play-icon-wit.svg");
        background-position: center;
    }
    &--underline {
        color: $primary;
        display: inline-block;
        padding-bottom: 0;
        width: auto;
        &:after {
            content:'';
            display: block;
            width: 50%;
            height: 2px;
            background-color: $yellow;
            margin: .375rem auto 0;
        }
    }
}
.text-button {
    font-weight: 600;
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 24px;
    transition: .5s;
    color: $blue;
    background-image: url("/img/site/icons/pijltje-rechts-blauw.svg");
    &:hover {
        background-position: center right;
    }
    &--white {
        color: $white !important;
        background-image: url("/img/site/icons/pijltje-rechts-wit.svg");
    }
}
@media screen and (min-width: $medium) {
    .button {
        background-position: center right 32px;
        &:hover {
            background-position: center right 16px;
        }
        &--arrow,
        &--instagram,
        &--linkedin,
        &--flag,
        &--plus {
            padding: 40px;
            background-position: center right 32px;
        }
        &--arrow {
            &:hover {
                background-position: center right 32px;
            }
        }
        &--flag {
            margin-top: calc(100vh - 96px);
            z-index: 9999;
            left: 8px;
            display: block !important;
        }
    }
    .text-button {
        padding-right: 40px;
        background-position: center right 16px;
        &:hover {
            background-position: center right;
        }
    }
}
