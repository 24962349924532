.content {
    &__box {
        padding: 0;
    }
    &__banner {
        height: 240px;
        background-size: cover;
        background-position: center center;
    }
}
@media screen and (min-width: $medium) {
    .content {
        &__box {
            padding: 250px 0;
        }
        &__banner {
            height: 80vh;
        }
    }
    .slider-matrix__main {
        img {
            max-height: 400px;
        }
    }

}
@media screen and (max-width: $medium) {
    .content {
        &__background {
            width: 100% !important;
            height: 520px;
        }
        &__video {
            width: 50% !important;
            height: 360px;
        }
    }
    .slider-matrix__main {
        img {
            max-height: 300px;
        }
    }
    .slider-matrix--thumb {
        img {
            max-height: 80px;
        }
    }
}

@media screen and (min-width: $large) {
    .slider-matrix__main {
        img {
            max-height: 500px;
        }
    }
    .slider-matrix--thumb {
        img {
            max-height: 150px;
        }
    }
}

@media screen and (min-width: $xlarge) {
    .slider-matrix__main {
        img {
            max-height: 600px;
        }
    }
}

@media screen and (min-width: $large) {
    .home-video--margin-left {
        margin-left: -30%;
    }
}


::selection {
    background: $blue; /* WebKit/Blink Browsers */
    color: #fff;
}
::-moz-selection {
    background: $primary; /* Gecko Browsers */
    color: #fff;
}