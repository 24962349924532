* {
    .greyscaled,
    .already-greyscaled {
        &:hover {
            animation-name: greyscale-backwards;
            animation-duration: 1s;
            -webkit-animation-fill-mode: forwards;
        }
    }
    .already-greyscaled {
        animation-name: greyscale;
        animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
    }
    &.visible {
        animation-name: fade-in;
        animation-duration: 4s;
    }
    @-webkit-keyframes fade-in {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    &.greyscaled {
        animation-name: greyscale;
        animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
    }
    @-webkit-keyframes greyscale {
        from {-webkit-filter: grayscale(0%); -moz-filter: grayscale(0%); -o-filter: grayscale(0%); filter: grayscale(0%);}
        to {-webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%); filter: grayscale(100%);}
    }
    @-webkit-keyframes greyscale-backwards {
        from {-webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -o-filter: grayscale(100%); filter: grayscale(100%);}
        to {-webkit-filter: grayscale(0%); -moz-filter: grayscale(0%); -o-filter: grayscale(0%); filter: grayscale(0%);}
    }
}
