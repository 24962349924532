//Theme colours scss map is located in _colours.scss partial
@each $name, $colour in $theme-colours {
	.#{$name} {
		color: $colour;
	}
}

@each $name, $colour in $theme-colours {
	.hov\:#{$name} {
		transition: color 0.3s ease;

		&:hover {
			color: $colour;
		}
	}
}

.bold {
	font-weight: bold;
}

.italic {
	font-style: italic;
}

.small {
	font-size: 16px;
}

.uppercase {
	text-transform: uppercase;
}
.lowercase {
	text-transform: lowercase;
}
.underline {
	text-decoration: underline;
}

.justify {
	text-align: justify;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.spaced-letters {
	letter-spacing: 1px;
}

@each $prefix, $size in $screens {
    @media screen and (min-width: $size) {
        .#{$prefix}\:text-left {
            text-align: left;
        }

        .#{$prefix}\:text-right {
            text-align: right;
        }

        .#{$prefix}\:text-center {
            text-align: center;
        }

        .#{$prefix}\:text-justify {
            text-align: justify;
        }

        @each $name, $colour in $theme-colours {
            .#{$prefix}\:#{$name} {
                color: $colour;
            }
        }
    }
}

.break-word {
	word-wrap: break-word;
}

