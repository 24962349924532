.alert {
    background-color: #f6d502;
    padding: 10px;
    margin: 15px;
    @media only screen and (min-width: $large) {
        padding: 20px 40px;
        margin: 0;
    }
    text-align: left;
    p {
        margin: 0;
    }
}

.alert {
    position: relative;
    border: 1px solid transparent;
}

.alert-heading {
    color: inherit;
}

.alert p a {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-primary hr {
    border-top-color: #9fcdff;
}

.alert-primary p a {
    color: #002752;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary p a {
    color: #202326;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-success hr {
    border-top-color: #b1dfbb;
}

.alert-success p a {
    color: #0b2e13;
}

.alert-info {
    color: $colour-fourth;
    background-color: $colour-fourth;
    border-color: $colour-primary-darkened;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info p a {
    color: #062c33;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning p a {
    color: #533f03;
}

.alert-danger {
    color: #fff;
    background-color: $colour-primary;
    border-color: $colour-primary;
}

.alert-danger hr {
    border-top-color: $colour-primary-darkened;
}

.alert-danger p a {
    color: #491217;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light p a {
    color: #686868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alert-dark hr {
    border-top-color: #b9bbbe;
}

.alert-dark p a {
    color: #040505;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}
