.nav {
    line-height: 0;
    width: 100%;
    &__holder {
        height: 64px;
        width: 100%;
    }
    &__logo {
        width: 106px;
        height: 40px;
        background-image: url('/img/icons/logo-corporate-fashion.png');
        background-size: 106px 40px;
        left: 16px;
        top: 20px;
    }
    .nav-toggle-outer {
        z-index: 9999;
        @media only screen and (min-width: $medium) {
            height: 110px;
        }
        .nav-toggle {
            width: 20px;
            height: 22px;
            line-height: 2;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            &:after {
                margin-top: -4px;
                content: 'Menu';
                position: absolute;
                display: block;
                width: auto;
                left: auto;
                right: 41px;
                text-align: center;
                color: white;
                font-weight: 600;
            }
            span {
                display: block;
                position: absolute;
                height: 2px;
                width: 20px;
                background: $grey;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;
                &:nth-child(1) {
                    top: 0;
                }
                &:nth-child(2), &:nth-child(3) {
                    top: 10px;
                }
                &:nth-child(4) {
                    top: 20px;
                }
            }
            &.nav-open {
                &:after {
                    content: 'Sluit';
                }
                span {
                    &:nth-child(1) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                    &:nth-child(2) {
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    &:nth-child(3) {
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                    &:nth-child(4) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .main-menu {
        width: calc(100vw - 16px);
        height: calc(100vh - 16px);
        &__holder {
            flex: 0 0 83.333333%;
            max-width: 83.333333%;
            ul {
                margin-top: 80px;
                padding: 64px 0;
            }
        }
        .menu-item {
            transition: .5s;
            margin-left: 0;
            &:hover {
                margin-left: 32px;
            }
        }
        &__image {
            background-size: cover;
            background-position: center;
            opacity: .3;
        }
        &.nav-open {
            display: flex;
        }
    }
}

@media screen and (min-width: $medium) {
    .nav {
        width: 80px;
        &__logo {
            height: 48px;
            width: 127px;
            right: 24px;
            top: 24px;
            left: auto;
            background-size: 127px 48px;
        }
        &__holder {
            height: 100%;
            width: 80px;
        }
        .main-menu {
            &__holder {
                width: 200px;
                max-width: none;
                flex: 0;
                ul {
                    width: 200px;
                    margin-top: 0;
                    padding: 0;
                }
            }
            &__background {
                height: 100% !important;
            }
        }
        .nav-toggle-outer {
            .nav-toggle {
                margin: 30px;
                &:after {
                    left: -30px !important;
                    right: auto !important;
                    margin-top: 38px !important;
                    width: 80px;
                }
            }
        }
    }
}
@media screen and (max-width: $medium) {
    .nav-toggle-outer {
        padding: 21px 22px 21px 75.47px;
        right: 8px;
    }
}
