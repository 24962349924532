.header {
    &__image {
        &--staand {
            pointer-events: all;
        }
        &--liggend {
        }
        div {
            float: right;
            height: 660px;
        }
    }
    .layer-2 {
        margin-top: 0;
        padding-bottom: 64px;
        pointer-events: none;
        .header {
            &__image {
                div {
                    height: 570px;
                }
            }
        }
    }
    .layer-1 {
        @media only screen and (min-width: $medium) and (max-width:$large) {
            h1 {
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
    p {
        @media only screen and (min-width: $large) {
            column-count: 1;
            -webkit-column-gap: 32px; /* Chrome, Safari, Opera */
            -moz-column-gap: 32px; /* Firefox */
            column-gap: 32px;
        }
    }
}
.header-content {
    margin-top: -80px;
    padding: 0 0 104px 0;
    span {
        line-height: 64px;
    }
    &--projecten {
        padding-bottom: 0;
    }
    .container {
        .scroll-position {
            bottom: -104px;
            right: 0;
        }
    }
    p {
        column-count: 0;
        -webkit-column-gap: 0; /* Chrome, Safari, Opera */
        -moz-column-gap: 0; /* Firefox */
        column-gap: 0;
    }
    &__text-box {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-left: 16px;
    }
    &__background-image {
        height: 245px;
        background-size: cover;
        background-position: center center;
    }
    &__button-holder {
        bottom: 0;
    }
}
@media screen and (min-width: $medium) {
    .header {
        &__scroll {
            display: none;
        }
        .layer-2 {
            margin-top: -390px;
            @media only screen and (max-width: $medium) {
                img {
                    display: none;
                }
            }
            @media only screen and (min-width: $medium) {
                margin-top: -11%;
            }
            @media only screen and (min-width: $large) {
                margin-top: -20%;
            }
            @media only screen and (min-width: $slarge) {
                margin-top: -25%;
            }
            @media only screen and (min-width: $xlarge) {
                margin-top: -31%;
            }
            padding-bottom: 120px;
        }
        p {
            @media only screen and (min-width: $large) {
                column-count: 2;
            }
            margin-bottom: 0;
        }
    }
    .header-content {
        padding: 80px 0 0 0;
        span {
            line-height: 80px;
        }
        .container {
            .scroll-position {
                bottom: -80px;
                left: -131.72px;
            }
        }
        &__button-holder {
            bottom: -80px;
        }
        &__background-image {
            height: 500px;
        }
        &__text-box {
            padding: 80px 0 80px 0;
            margin-right: 0px;
            width: 50%;
        }
    }
}
@media screen and (max-width: $medium) {
    .header {
        &__text {
            width: 100% !important;
            h1 {
                word-spacing: 9999px;
            }
        }
    }
    .header-content {
        margin-top: -80px;
        &__background-image {
            width: auto !important;
        }
    }
}
