.projecten {
    padding: 80px 0;
    &__pagination {
        padding: 32px;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .project-button-container {
        min-height: 40px;
    }
    &__arrows {
        bottom: 0;
        width: 100%;
        left: 0;
        top: auto;
        z-index: 5;
        span {
            &:nth-child(1) {
                margin-top: 14px;
                margin-left: 21px;
            }
            &:nth-child(2) {
                margin-top: 20px;
                margin-left: 29.5px;
            }
            &:nth-child(3) {
                margin-top: 26px;
                margin-left: 37px;
            }
        }
    }
    &__image-holder {
        div {
            height: 116px;
            background-size: cover;
            background-position: center center;
        }
        &--one,
        &--four {
            div {
                height: 240px;
            }
        }
    }
}
.project-slider {
    &__pagination {
        .slick-slide.slick-current{
        span {
                color: $white;
                border-bottom: 2px solid $white;
            }
        }
    }
    &__holder {
        margin-bottom: 88px;
        max-width: none;

    }
    &__image {
        height: 400px;
        background-size: cover;
        background-position: center center;
    }
}
@media screen and (min-width: $medium) {
    .projecten {
        &__pagination {
            padding: 40px;
        }
        &__arrows {
            top: 50%;
            transform: translateY(-50%);
            left: -80px;
            width: 80px;
            span {
                &:nth-child(1) {
                    margin-top: 21px;
                    margin-left: 26px;
                }
                &:nth-child(2) {
                    margin-top: 27px;
                    margin-left: 34.5px;
                }
                &:nth-child(3) {
                    margin-top: 33px;
                    margin-left: 43px;
                }
            }
        }
        &__image-holder {
            div {
                height: 269px;
                background-size: cover;
                background-position: center center;
            }
            &--one,
            &--four {
                div {
                    height: 570px;
                    background-size: cover;
                    background-position: center center;
                }
            }
        }
    }
    .project-slider {
        &__image {
            height: 570px;
        }
        &__holder {
            margin-bottom: 80px;
        }
    }
}
