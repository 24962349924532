// http://chir.ag/projects/name-that-color/

// Core colours
$transparent: transparent !important;
$primary: #16191e;
$primary-opacity: rgba(22, 25, 30, .1) !important;
$primary-light: #212429;
$primary-light-opacity: rgba(34, 127, 188, .1);
$white: #ffffff;
$white-opacity: rgba(255, 255, 255, .1);
$black: #000000;
$charcoal: #414042;
$grey: #8C93A3;
$grey-light: rgba(140, 147, 163, .25);
$grey-opacity: rgba(0, 0, 0, .03);
$grey-dark: #3e4b51;

// Greys
$gallery: #eeeeee;

// Theme Colours
$forest-green: #9cc68b;
$pink: #5b108b;
$purple: #ae2a71;
$palm-green: #0d270a;
$yellow: #ffbf1f;
$red: #9f2323;
$navy-blue-invisible: rgba(27, 45, 60, .0);
$navy-blue: #1b2d3c;
$blue: #0082c8 !important;
$light-blue: #f8fbfd;
$light-blue-x: #f3f4f8;
$orange: #ff9b38;
$verdino-blue: #014289;
$green: #38c172;
$dark-green: #9cc68b;


// Variables
$colour-primary: $red;
$colour-accent: $pink;
$colour-secondary: $orange;
$colour-fourth: $yellow;

//Variables darkened
$colour-primary-darkened: darken($colour-primary, 10%);
$colour-accent-darkened: darken($colour-accent, 10%);
$colour-secondary-darkened: darken($colour-secondary, 10%);


$colour-background: $white;
$colour-inner-background: $gallery;

$colour-font: $grey;

//social site colours
$twitter-blue: #55ACEE;
$whatsapp-green: #48C758;
$linkedin-blue: #0077B5;
$facebook-blue: #39619F;
$google-red: #F33F2C;

$theme-colours: (
	transparent: $transparent,
	primary: $primary,
	primary-opacity: $primary-opacity,
	primary-light: $primary-light,
	primary-light-opacity: $primary-light-opacity,
	white: $white,
	white-opacity: $white-opacity,
	black: $black,
	grey: $grey,
	grey-opacity: $grey-opacity,
	charcoal: $charcoal,
	colour-primary: $colour-primary,
	colour-accent: $colour-accent,
	colour-secondary: $colour-secondary,
	colour-primary-darkened: $colour-primary-darkened,
	colour-accent-darkened: $colour-accent-darkened,
	colour-secondary-darkened: $colour-secondary-darkened,
	colour-background: $colour-background,
	colour-inner-background: $colour-inner-background,
	colour-font: $colour-font,
	forest-green: $forest-green,
	dark-green: $forest-green,
	green: $green,
	pink: $pink,
	orange: $orange,
	yellow: $yellow,
	red: $red,
	navy-blue-invisible: $navy-blue-invisible,
	navy-blue: $navy-blue,
	blue: $blue,
	light-blue: $light-blue,
	light-blue-x: $light-blue-x,
	gallery: $gallery,
	palm-green: $palm-green,
	verdino-blue: $verdino-blue,
	grey-dark: $grey-dark,
	grey-light: $grey-light,
	whatsapp-green: $whatsapp-green,
	linkedin-blue: $linkedin-blue,
	twitter-blue: $twitter-blue
);

